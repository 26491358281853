import { ReactNode } from 'react';
import {
  /*  SupervisedUserCircle,
  SettingsPhone, */
  Book,
  StorefrontTwoTone,
  AssignmentInd,
  //CloudUploadTwoTone,
  //Cached,
  RecentActors,
  //Description,
  //Assignment,
  //FindInPage,
  DynamicFeed,
  HelpOutline,
  Assessment,
  /* TrackChanges, */
  EventNote,
  Today,
  Home,
  /* Help, */
  Accessibility,
  Description,
  FlagOutlined,
  FindInPage,
  Call,
  Build,
  HelpCenter,
  Dashboard,
  ViewColumn,
} from '@material-ui/icons';

import { Role, Instance } from '../../../../common/types/user';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
  roles?: Role[];
  instances?: Instance[];
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  roles?: Role[];
  instances?: Instance[];
}

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboards',
    roles: ['admin'],
    items: [
      {
        name: 'Inicio',
        icon: Home,
        link: '/dashboards/inicio-new',
      },
      {
        name: 'Funnels',
        icon: StorefrontTwoTone,
        link: '/dashboards/reports',
        items: [
          {
            name: 'Funnel Digital',
            link: '/dashboards/reports/exata-digital-funnel',
          },
          {
            name: 'Funnel Digital DDP',
            link: '/dashboards/reports/exata-digital-funnel-ddp',
          },
          {
            name: 'Resumen Funnel',
            link: '/dashboards/reports-exata/summary',
          },
        ],
      },
      {
        name: 'Contactabilidad',
        icon: RecentActors,
        link: '/dashboards/exata/contactability',
      },
      {
        name: 'Reportes de Ventas',
        icon: Description,
        link: '/dashboards/exata-sales-reports',
      },
      {
        name: 'Inxait',
        icon: DynamicFeed,
        link: '/dashboards/inxait',
      },
      {
        name: 'Confirmaciones',
        icon: EventNote,
        link: '/dashboards/inxait-events',
      },

      {
        name: 'Preguntas Frecuentes',
        icon: HelpOutline,
        instances: ['ar', 'br', 'py', 'uy'],
        link: '/dashboards/faq',
      },
      {
        name: 'Accesos Diarios',
        icon: Assessment,
        link: '/dashboards/daily-access',
      },
      {
        name: 'Banderas',
        icon: FlagOutlined,
        link: '/dashboards/flags',
      },
      {
        name: 'Tickets',
        icon: HelpCenter,
        items: [
          {
            name: 'Tablero',
            icon: ViewColumn,
            link: '/dashboards/tickets',
          },
          {
            name: 'Dashboard',
            icon: Dashboard,
            link: '/dashboards/board',
          },
        ],
      },
      {
        name: 'Configuraciones',
        icon: Build,
        link: '/dashboards/configurations',
      },
    ],
  },
  {
    heading: 'Dashboards',
    roles: ['gm'],
    items: [
      {
        name: 'Inicio',
        icon: Home,
        link: '/dashboards/inicio-new',
      },
      {
        name: 'Reportes de Ventas',
        icon: Description,
        link: '/dashboards/exata-sales-reports',
      },
      {
        name: 'Funnels',
        icon: StorefrontTwoTone,
        link: '/dashboards/reports',
        items: [
          {
            name: 'Funnel Digital',
            link: '/dashboards/reports/exata-digital-funnel',
          },
          {
            name: 'Funnel Digital DDP',
            link: '/dashboards/reports/exata-digital-funnel-ddp',
          },
          {
            name: 'Resumen Funnel',
            link: '/dashboards/reports-exata/summary',
          },
        ],
      },
      {
        name: 'Contactabilidad',
        icon: RecentActors,
        link: '/dashboards/exata/contactability',
      },

      {
        name: 'Tickets',
        icon: HelpCenter,
        items: [
          {
            name: 'Dashboard',
            icon: Dashboard,
            link: '/dashboards/board',
          },
          {
            name: 'Crear ticket',
            icon: HelpCenter,
            link: '/dashboards/dealer-tickets',
          },
          /*  {
      name: 'Dashboard',
      icon: Dashboard,
      link: '/dashboards/board',
    }, */
        ],
      },
      {
        name: 'Consultar Leads',
        icon: FindInPage,
        link: '/dashboards/search-leads',
      },
      {
        name: 'Preguntas Frecuentes',
        icon: HelpOutline,
        instances: ['ar', 'br', 'py', 'uy'],
        link: '/dashboards/faq',
      },
    ],
  },

  {
    heading: 'Dashboards',
    roles: ['dealer'],
    items: [
      {
        name: 'Inicio',
        icon: Home,
        link: '/dashboards/inicio-new',
      },
      {
        name: 'Reportes de Ventas',
        icon: Description,
        link: '/dashboards/exata-sales-reports',
      },
      /*  {
        name: 'Tickets',
        icon: HelpCenter,
        link: '/dashboards/dealer-tickets',
      }, */
      {
        name: 'Funnels',
        icon: StorefrontTwoTone,
        link: '/dashboards/reports',
        items: [
          {
            name: 'Funnel Digital',
            link: '/dashboards/reports/exata-digital-funnel',
          },
          {
            name: 'Funnel Digital DDP',
            link: '/dashboards/reports/exata-digital-funnel-ddp',
          },
          {
            name: 'Resumen Funnel',
            link: '/dashboards/reports-exata/summary',
          },
        ],
      },
      {
        name: 'Contactabilidad',
        icon: RecentActors,
        link: '/dashboards/exata/contactability',
        /*  items: [
    {
      name: 'Carga',
      link: '/dashboards/contactability/upload',
    },
    {
      name: 'Validación',
      link: '/dashboards/contactability/validation',
    },
    {
      name: 'Reporte',
      link: '/dashboards/contactability/report',
    },
    {
      name: 'Base Encuestas',
      link: '/dashboards/contactability/survey-base',
    },
  ],
      link: '/dashboards/contactability/survey-base'
    }
  ] */
      },
      {
        name: 'Consultar Leads',
        icon: FindInPage,
        link: '/dashboards/search-leads',
      },
      {
        name: 'Preguntas Frecuentes',
        icon: HelpOutline,
        instances: ['ar', 'br', 'py', 'uy'],
        link: '/dashboards/faq',
      },
    ],
  },
  {
    heading: 'Otros',
    roles: ['admin'],
    instances: ['cl', 'co'],
    items: [
      {
        name: 'Horarios de Expertos',
        icon: Today,
        link: '/dashboards/expert-calendars',
      },
      {
        name: 'Panel del Experto',
        icon: AssignmentInd,
        link: '/dashboards/expert-panel',
      },
      {
        name: 'Dealer Supervisor',
        icon: Book,
        link: '/dashboards/dealer-supervisor',
      },
      {
        name: 'Dealer Callcenter',
        icon: Call,
        link: '/dashboards/dealer-callcenter',
      },
      {
        name: 'Live Store',
        icon: Accessibility,
        instances: ['cl', 'co'],
        link: '/dashboards/livestore',
        items: [
          {
            name: 'Gestionar',
            link: '/dashboards/livestore/management',
          },
          {
            name: 'Dealers',
            link: '/dashboards/livestore/dealers',
          },
          {
            name: 'Reporte',
            link: '/dashboards/livestore/report',
          },
          {
            name: 'GMF',
            link: '/dashboards/livestore/gmf',
          },
        ],
      },
    ],
  },
  {
    heading: 'Otros',
    roles: ['gm'],
    instances: ['cl'],
    items: [
      /* {
  name: 'Live Store',
  icon: Accessibility,
  link: '/dashboards/livestore',
  items: [
    {
      name: 'Dealers',
      link: '/dashboards/livestore/dealers',
    },
    {
      name: 'Reporte',
      link: '/dashboards/livestore/report',
    },
  ],
}, */
    ],
  },
  {
    heading: 'Otros',
    roles: ['gm'],
    instances: ['co'],
    items: [
      /*  {
   name: 'Live Store',
   icon: Accessibility,
   link: '/dashboards/livestore',
   items: [
     {
       name: 'Reporte',
       link: '/dashboards/livestore/report',
     },
   ],
 }, */
    ],
  },
  {
    heading: 'Otros',
    roles: ['gm'],
    instances: ['co'],
    items: [
      /*   {
    name: 'Live Store',
    icon: Accessibility,
    link: '/dashboards/livestore',
    items: [
      {
        name: 'Reporte',
        link: '/dashboards/livestore/report',
      },
    ],
  }, */
    ],
  },
  {
    heading: 'Dashboards',
    roles: ['inxait'],
    instances: ['cl'],
    items: [
      {
        name: 'Inicio',
        icon: Home,
        link: '/dashboards/inicio-new',
      },
      /* {
  name: 'Tickets',
  icon: AirplaneTicket,
  link: '/dashboards/tickets'
}, */
    ],
  },
  {
    heading: 'Otros',
    roles: ['admin', 'gm'],
    instances: ['pe'],
    items: [
      {
        name: 'DDP',
        icon: Description,
        link: '/dashboards/ddp-report',
      },
    ],
  },
  {
    heading: 'Dashboards',
    roles: ['callcenter'],
    instances: ['cl', 'ec', 'pe'],
    items: [
      {
        name: 'Inicio',
        icon: Home,
        link: '/dashboards/inicio-new',
      },
      {
        name: 'Reportes de Ventas',
        icon: Description,
        link: '/dashboards/exata-sales-reports',
      },
    ],
  },
  {
    heading: 'Dashboards',
    roles: ['nodo'],
    instances: ['cl'],
    items: [
      /* {
  name: 'Live Store',
  icon: Accessibility,
  link: '/dashboards/livestore',
  items: [
    {
      name: 'Gestionar',
      link: '/dashboards/livestore/management',
    },
    {
      name: 'Experiencía Virtual',
      link: '/dashboards/livestore/virtual-experience',
    }
  ],
}, */
    ],
  },
  {
    heading: 'Dashboards',
    roles: ['autotrain'],
    instances: ['cl'],
    items: [
      /*  {
   name: 'Live Store',
   icon: Accessibility,
   link: '/dashboards/livestore',
   items: [
     {
       name: 'Experiencía Virtual',
       link: '/dashboards/livestore/virtual-experience',
     }
   ],
 }, */
    ],
  },
  {
    heading: 'Dashboards',
    roles: ['callcenter'],
    instances: ['co'],
    items: [
      {
        name: 'Inicio',
        icon: Home,
        link: '/dashboards/inicio-new',
      },
      {
        name: 'Reportes de Ventas',
        icon: Description,
        link: '/dashboards/exata-sales-reports',
      },
    ],
  },
  /* {
heading: 'Otros',
roles: ['dealer'],
instances: ['cl'],
items: [
{
  name: 'Live Store',
  icon: Accessibility,
  link: '/dashboards/livestore',
  items: [
    {
      name: 'Dealers',
      link: '/dashboards/livestore/dealers',
    }
  ],
},
],
}, */

  /*  {
heading: 'Otros',
roles: ['dealer'],
instances: ['co'],
items: [
 {
   name: 'Dealer Supervisor',
   icon: Book,
   link: '/dashboards/dealer-supervisor',
 },
 {
   name: 'Dealer Callcenter',
   icon: Call,
   link: '/dashboards/dealer-callcenter',
 },
],
}, */
];

export default menuItems;
