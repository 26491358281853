import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import { experimentalStyled } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { useTaskReducer } from '../Tasks/reducer';
import { colors } from '../../../../../theme/schemes/GmTheme';

const NotificationsBadge = experimentalStyled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);

function HeaderNotifications() {
  const { getLastDirectUpload, task, status } = useTaskReducer();
  useEffect(() => {
    getLastDirectUpload();
    // eslint-disable-next-line
  }, []);
  const lastUploadDate = status === 'complete' && new Date(task?.createdAt);
  /*  const lastUploadDatePlus =
    status === 'complete' &&
    format(new Date(task.createdAt), 'yyyy/MM/dd HH:mm');
  const dateParse = new Date(lastUploadDatePlus);
  dateParse.setHours(dateParse.getHours() + 1, dateParse.getMinutes() + 30); */
  // obtener la hora actual
  const nowDate = new Date();
  nowDate.toString();
  const parseNowDate = format(new Date(nowDate), 'yyyy/MM/dd HH:mm');
  const toDate = new Date(parseNowDate);
  //
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isNotify, setNotify] = useState(0);
  const [isLate, setLate] = useState('');
  const handleOpen = (): void => {
    setOpen(true);
  };
  var CronJob = require('cron').CronJob;
  var job = new CronJob('* 59 * * * 0-6', function () {
    lastUploadDate < toDate ? setNotify(1) : setNotify(0);
    lastUploadDate < toDate ? setLate('retraso') : setLate('Al Dia');
  });
  job.start();
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={'Notificaciones'}>
        <IconButton
          sx={{ color: colors.gm.white }}
          ref={ref}
          onClick={handleOpen}
        >
          <NotificationsBadge
            badgeContent={lastUploadDate < nowDate ? 1 : 0 || isNotify}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2 }} display="flex" justifyContent="space-between">
          <Typography variant="h5">{'Notificaciones'}</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 2 }}>
          <ListItem
            sx={{ display: { xs: 'block', sm: 'flex' } }}
            button
            selected
          >
            <Box flex="1">
              <Box
                display={{ xs: 'block', sm: 'flex' }}
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  Ultima Carga Directa
                </Typography>
              </Box>
              {status === 'complete' && (
                <Typography sx={{ fontWeight: 'bold' }}>
                  {format(new Date(task?.createdAt), 'yyyy/MM/dd HH:mm')}
                </Typography>
              )}
              {lastUploadDate < nowDate ? (
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: (theme: Theme) => theme.palette.error.main }}
                >
                  {'Retraso' || isLate}
                </Typography>
              ) : (
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: (theme: Theme) => theme.palette.success.main }}
                >
                  {'Al dia' || isLate}
                </Typography>
              )}
            </Box>
          </ListItem>
        </List>
        <Divider />
      </Popover>
    </>
  );
}

export default HeaderNotifications;
