import { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

interface GuestProps {
  children?: ReactNode;
}

const Guest: FC<GuestProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    switch (user.roles[0]?.name) {
      case 'admin':
        return <Redirect to="/dashboards/inicio-new" />;
      case 'dealer':
        return <Redirect to="/dashboards/inicio-new" />;
      case 'gm':
        return <Redirect to="/dashboards/inicio-new" />;
      case 'callcenter_dealer':
        return <Redirect to="/dashboards/dealer-supervisor" />;
      case 'inxait':
        return <Redirect to="/dashboards/inxait" />;
      case 'supervisor_dealer':
        return <Redirect to="/dashboards/dealer-supervisor" />;
      case 'autotrain':
        return <Redirect to="/dashboards/livestore/virtual-experience" />;
      case 'callcenter':
        return <Redirect to="/dashboards/inicio-new" />;
      case 'nodo':
        return <Redirect to="/dashboards/livestore/management" />;
      default:
        return <Redirect to="/dashboards/faq" />;
    }
  }
  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node,
};

export default Guest;
