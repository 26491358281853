import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Autocomplete,
  TextField,
  Box,
  Typography,
  Button,
} from '@material-ui/core';

import { experimentalStyled } from '@material-ui/core/styles';

import { useFtpReducer } from './reducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      color: 'white !important',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.dark} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.main} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.light} !important`,
      },
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: 'red !important',
      },
    },
  }),
);

const UploadBox = experimentalStyled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
`,
);

const TypographyPrimary = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[100]};
  `,
);

const TypographySecondary = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[70]};
  `,
);

type DownloadTypes = 'OVERALL' | 'FRANCHISE' | 'VENTAS';

const AvailableDownloadTypes: { label: string; value: DownloadTypes }[] = [
  {
    label: 'Leads Overall',
    value: 'OVERALL',
  },
  {
    label: 'Leads Franchise',
    value: 'FRANCHISE',
  },
  {
    label: 'Ventas',
    value: 'VENTAS',
  },
];

const SelectFtpDownload = () => {
  const styles = useStyles();
  const { selectFtpTypeFile, ftpFiles } = useFtpReducer();
  return (
    <UploadBox>
      <TypographyPrimary variant="h4" gutterBottom>
        Descargar archivos de ftp
      </TypographyPrimary>
      <TypographySecondary variant="body1">
        Seleccione el tipo de archivo
      </TypographySecondary>

      <Box sx={{ my: 2 }}>
        <Autocomplete
          classes={styles}
          disablePortal
          options={AvailableDownloadTypes}
          onChange={(
            _,
            value: { label: string; value: DownloadTypes } | null,
          ) => selectFtpTypeFile(value ? value.value : null)}
          defaultValue={{
            label: 'Leads Overall',
            value: 'leads_overAll',
          }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              fullWidth
              label="Tipo de archivo"
            />
          )}
        />

        <Box my={2}>
          {ftpFiles.length > 0 ? (
            ftpFiles.map((file) => (
              <Box>
                <div
                  className="label-box"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <small>{file.label}</small>
                </div>

                <a
                  href={file.value}
                  target="_blank"
                  rel="noreferrer"
                  download
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    style={{ marginTop: 2 }}
                  >
                    Descargar
                  </Button>
                </a>
              </Box>
            ))
          ) : (
            <p>no tienes archivos ftp para descargar</p>
          )}
        </Box>
      </Box>
    </UploadBox>
  );
};

export default SelectFtpDownload;
