import { useReducer } from 'react';

import axios from '../../../../../utils/axios';
import { Lead } from '../../../../../common/types/lead';

type OpenModal = {
  type: 'open-modal';
  payload: {
    leads: Lead[];
  };
};

type CloseModal = {
  type: 'close-modal';
};

type HandleSearchChange = {
  type: 'handle-search-change';
  payload: {
    field: string;
    value: any;
  };
};

type Action = OpenModal | CloseModal | HandleSearchChange;

type State = {
  status: 'idle' | 'loading' | 'changing';
  toSearch: string;
  leads: Lead[];
  isModalOpen: boolean;
};

const initialState: State = {
  status: 'changing',
  toSearch: '',
  leads: [],
  isModalOpen: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'handle-search-change': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
        status: 'changing',
        leads: [],
      };
    }
    case 'open-modal': {
      const { leads } = action.payload;
      return {
        ...state,
        isModalOpen: true,
        leads,
        status: 'idle',
      };
    }
    case 'close-modal': {
      return {
        ...state,
        isModalOpen: false,
      };
    }
    default:
      return state;
  }
};

export interface ReducerValue extends State {
  handleSearchChange: (field: string, value: any) => void;
  searchLead: () => void;
  openModal: () => void;
  closeModal: () => void;
}

export const useCustomReducer = (): ReducerValue => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSearchChange = (field: string, value: any) => {
    dispatch({
      type: 'handle-search-change',
      payload: {
        field,
        value,
      },
    });
  };

  const searchLead = async () => {
    if (state.toSearch.trim() !== '') {
      const { data: leads } = await axios.get<Lead[]>(`/leads/search`, {
        params: {
          search: state.toSearch,
        },
      });
      dispatch({
        type: 'open-modal',
        payload: {
          leads,
        },
      });
    }
  };

  const openModal = () => {
    dispatch({
      type: 'open-modal',
      payload: {
        leads: [],
      },
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'close-modal',
    });
  };

  return {
    ...state,
    handleSearchChange,
    searchLead,
    openModal,
    closeModal,
  };
};
