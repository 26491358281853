import { Type } from './../../../../../common/types/task';
import { useSnackbar } from 'notistack';
import axios from '../../../../../utils/axios';
import { useEffect, useReducer } from 'react';
import { Task } from 'src/common/types/task';

type GenerateFtpCharge = {
  type: 'generate-ftp-charge';
};
type LoadingDirectUpload = {
  type: 'loadingLastUpload';
  payload: {
    task: Task;
  };
};
type getAllTasks = {
  type: 'getAllTasks';
  payload: {
    tasks: Task[];
  };
};

type ActionTypes = GenerateFtpCharge | LoadingDirectUpload | getAllTasks;

type State = {
  status: 'not-generated' | 'generated' | string;
  task: Task;
  tasks: Task[];
};

const initialState: State = {
  status: 'not-generated',
  task: null,
  tasks: [],
};

const reducer = (state: State, action: ActionTypes) => {
  switch (action.type) {
    case 'loadingLastUpload':
      const { task } = action.payload;
      return {
        ...state,
        task,
        status: 'complete',
      };
    case 'getAllTasks':
      const { tasks } = action.payload;
      return {
        ...state,
        tasks,
        status: 'complete',
      };
    case 'generate-ftp-charge':
      return {
        ...state,
        status: 'generated',
      };

    default:
      return state;
  }
};

export interface ReducerValue extends State {
  generateFtpCharge: () => void;
}

export const useFtpReducer = (): ReducerValue => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const getLastDirectUpload = async () => {
    const query = 'limit=5&page=1&sort%5B0%5D=createdAt%2CDESC';
    const { data: tasks } = await axios.get(`/tasks?${query}`);
    const lastFtpTask: Task = tasks.data.filter(
      (task: Task) => task.type === Type.IMPORT_FTP_OVERALL,
    )[0];
    dispatch({
      type: 'loadingLastUpload',
      payload: {
        task: lastFtpTask,
      },
    });
  };
  const generateFtpCharge = async () => {
    try {
      await axios.post('/ftp/import-ftp-overall-leads');
      dispatch({
        type: 'generate-ftp-charge',
      });
      enqueueSnackbar('se corrio el cron con exito.', { variant: 'success' });
    } catch (error) {
      console.error(error, 'ftp-charge');
      enqueueSnackbar('ocurrio un error al correr el cron.', {
        variant: 'error',
      });
    }
  };
  useEffect(() => {
    getLastDirectUpload();
  }, []);
  return {
    ...state,
    generateFtpCharge,
  };
};
