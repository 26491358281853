import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Autocomplete,
  TextField,
  Box,
  Typography,
  Button,
} from '@material-ui/core';

import { experimentalStyled } from '@material-ui/core/styles';

import { useFtpReducer } from './reducer';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      color: 'white !important',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.dark} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.main} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.light} !important`,
      },
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: 'red !important',
      },
    },
  }),
);

const UploadBox = experimentalStyled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
`,
);

const TypographyPrimary = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[100]};
  `,
);

const TypographySecondary = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[70]};
  `,
);

type UploadTypes = 'leads_overAll';

const AvailableUploadTypes: { label: string; value: UploadTypes }[] = [
  {
    label: 'Carga Leads Overall',
    value: 'leads_overAll',
  },
];

const SelectFtpUpload = () => {
  const styles = useStyles();
  const [upload, setUpload] = useState<UploadTypes | null>('leads_overAll');
  const { generateFtpCharge, task } = useFtpReducer();
  return (
    <UploadBox>
      <TypographyPrimary variant="h4" gutterBottom>
        Correr Cron
      </TypographyPrimary>
      <TypographySecondary variant="body1">
        Seleccione el tipo de carga
      </TypographySecondary>

      <Box sx={{ my: 2 }}>
        <Autocomplete
          classes={styles}
          disablePortal
          options={AvailableUploadTypes}
          onChange={(_, value: { label: string; value: UploadTypes } | null) =>
            setUpload(value ? value.value : null)
          }
          defaultValue={{
            label: 'Carga Leads Overall',
            value: 'leads_overAll',
          }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              fullWidth
              label="Tipo de carga"
            />
          )}
        />
        <Box p={0.5}>
          {task && (
            <>
              <p>
                {`Ultima Carga - ${format(
                  new Date(task?.createdAt),
                  'yyyy-MM-dd HH:mm:ss',
                )}`}
              </p>
            </>
          )}
        </Box>
        {upload === 'leads_overAll' && (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            style={{ marginTop: 10 }}
            onClick={() => generateFtpCharge()}
          >
            Generar Carga
          </Button>
        )}
      </Box>
    </UploadBox>
  );
};

export default SelectFtpUpload;
