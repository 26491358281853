import { forwardRef, Ref, ReactElement } from 'react';
import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import {
  experimentalStyled,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import { useCustomReducer } from './reducer';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    pageTitle: {
      ...theme.typography.h4,
      margin: 0,
    },
    main: {
      marginTop: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: '360px 720px',
      gridGap: theme.spacing(2),
      alignItems: 'start',
    },
    resultCard: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    result: {
      display: 'flex',
      flex: 2,
      flexDirection: 'column',
      border: '1px solid #bab8b8',
      margin: 10,
      padding: 10,
      '& > h4': {
        ...theme.typography.h5,
        margin: 0,
      },
      '& > .ci': {
        ...theme.typography.body1,
        // color: theme.palette.text.hint,
        marginBottom: theme.spacing(2),
      },
    },
    card: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    detail: {
      padding: theme.spacing(1),
      display: 'flex',
      minWidth: '120px',
      flexDirection: 'column',
      '& > strong': {
        ...theme.typography.subtitle1,
        color: theme.palette.text.secondary,
      },
      '& > span': {
        ...theme.typography.body1,
      },
    },
    buttonContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    loading: {
      position: 'absolute',
      justifySelf: 'center',
    },
    error: {
      marginBottom: theme.spacing(2),
    },
    placeholder: {
      minHeight: 240,
      borderRadius: theme.shape.borderRadius,
      border: `2px dashed ${theme.palette.divider}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > span ': {
        ...theme.typography.h6,
        maxWidth: 200,
        textAlign: 'center',
        // color: theme.palette.text.hint
      },
    },
  }),
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = experimentalStyled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`,
);

const SearchInputWrapper = experimentalStyled(TextField)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    fontSize: 10;

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`,
);

const DialogTitleWrapper = experimentalStyled(DialogTitle)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`,
);

function HeaderSearch() {
  const reducerValue = useCustomReducer();
  const {
    isModalOpen,
    closeModal,
    searchLead,
    handleSearchChange,
    toSearch,
    leads,
    status,
  } = reducerValue;
  const styles = useStyles();

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          searchLead();
        }}
      >
        <SearchInputWrapper
          value={toSearch}
          sx={{
            borderRadius: '10px',
            background: 'rgba(255, 255, 255, 0.5)',
          }}
          name="toSearch"
          onChange={(e) => {
            handleSearchChange(e.target.name, e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              fontSize: 12,
            },
          }}
          placeholder="Número de cédula o email..."
          fullWidth
          label="Buscar Lead"
        />
      </form>

      <DialogWrapper
        open={isModalOpen}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={closeModal}
      >
        <DialogTitleWrapper>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              searchLead();
            }}
          >
            <SearchInputWrapper
              value={toSearch}
              autoFocus={true}
              name="toSearch"
              onChange={(e) => {
                handleSearchChange(e.target.name, e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Número de cédula o email..."
              fullWidth
              label="Buscar Lead"
            />
          </form>
        </DialogTitleWrapper>
        <Divider />

        <DialogContent>
          <Box
            sx={{ pt: 2, pb: 1, px: 2 }}
            display="flex"
            justifyContent="space-between"
            flexDirection={"column"}
          >
            {leads.length < 0 && status === 'idle' && (
              <h3
                style={{
                  margin: '10px auto',
                }}
              >
                No se han encontrado coincidencias
              </h3>
            )}
            {leads.length > 0 && (
              leads.map((lead) => (
                <div className={styles.result} key={lead.opportunityName}>
                  <h4>{`${lead.names} ${lead.lastNames}`}</h4>
                  <span className="ci">{`CI: ${lead.document}`}</span>
                  <div className={styles.detail}>
                    <strong>Teléfono</strong>
                    <span>{`${lead.mobile || lead.phone || lead.workPhone
                      }`}</span>
                  </div>
                  <div className={styles.detail}>
                    <strong>Email</strong>
                    <span>{lead.email}</span>
                  </div>
                  <div className={styles.detail}>
                    <strong>Modelo</strong>
                    <span>{lead.model}</span>
                  </div>
                  <div className={styles.detail}>
                    <strong>Punto de venta</strong>
                    <span>{lead.dealerDealership}</span>
                  </div>
                  <div className={styles.detail}>
                    <strong>Nombre de la oportunidad</strong>
                    <span>{lead.opportunityName}</span>
                  </div>
                  <div className={styles.detail}>
                    <strong>Fecha de creación</strong>
                    <span>{format(new Date(lead.creationDate), 'MMMM dd yyyy', { locale: es })}</span>
                  </div>
                  <div className={styles.detail}>
                    <strong>Modelo de compra</strong>
                    <span>{lead.modelName}</span>
                  </div>
                  <div className={styles.detail}>
                    <strong>Campaña</strong>
                    <span>{lead.campaignName}</span>
                  </div>
                  {lead.vin !== null && (
                    <div className={styles.detail}>
                      <strong>Fecha de compra</strong>
                      <span>{format(new Date(lead.retailInvoiceDate), 'MMMM dd yyyy', { locale: es })}</span>
                    </div>
                  )}
                </div>
              ))
            )}
          </Box>
        </DialogContent>
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
