// Generated by https://quicktype.io

export type Task = {
  id: number;
  type: Type;
  status: 'COMPLETADA' | 'PENDIENTE' | 'EJECUTANDO';
  filePath: string;
  errorsFilePath: null | string;
  data: Data | null;
  createdAt: string;
  updatedAt: string;
};

export enum TaskTypes {
  DIRECTS_UPLOAD = 'carga_directas',
  SOURCES_UPLOAD = 'carga_fuentes',
  MONTHLY_CLOSURE_UPLOAD = 'carga_cierre_mes',
  SALES_UPLOAD = 'carga_ventas',
  GENERATE_REPORT = 'generar_reporte',
  FUNNEL_UPLOAD = 'carga_funnels',
  CONTACTABILITY_UPLOAD = 'carga_contactabilidad',
  LIVE_STORE_UPLOAD = 'carga_live_store',
  REPROCESS_SALES_MONTH = 'reprocesar_ventas_mes',
  REPROCESS_SALES_YEAR = 'reprocesar_ventas_año',
  ASSSIGN_SALES_MONTH = 'asignar_ventas_mes',
  ASSSIGN_SALES_YEAR = 'asignar_ventas_año',
  VALIDATE_SALES_MONTH = 'validar_ventas_mes',
  VALIDATE_SALES_YEAR = 'validar_ventas_año',
  UPLOAD_CELL_RULES = 'cargar_reglas_de_celda',
  IMPORT_FTP_OVERALL = 'import_ftp_overall',
}

export type Data = {
  year?: string;
  month?: string;
  source?: Source;
  campaignId?: number;
};

export enum Source {
  CallCenter = 'CALL_CENTER',
  Generic = 'GENERIC',
}

export enum Type {
  CargaContactabilidad = 'carga_contactabilidad',
  CargaDirectas = 'carga_directas',
  CargaFuentes = 'carga_fuentes',
  CargaFunnels = 'carga_funnels',
  CargaVentas = 'carga_ventas',
  IMPORT_FTP_OVERALL = 'import_ftp_overall',
}
