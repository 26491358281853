import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Popover,
  Tooltip,
  Autocomplete,
  TextField,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import { subMonths } from 'date-fns';
import { Download } from '@material-ui/icons';

import axios from 'src/utils/axios';
import { generalConfig } from 'src/config';
import { useNotification } from 'src/hooks/useNotification';
import ConditionalRender from 'src/components/Guards/Conditional/ConditionalRender';

const DownloadButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
);

function ExataHeaderDownloadReportBox() {
  const { instanceSlug } = generalConfig;
  const ref = useRef<any>(null);
  const notify = useNotification();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date>(
    instanceSlug === 'cl'
      ? subMonths(new Date(), 13)
      : subMonths(new Date(), 5),
  );
  const [toDate, setToDate] = useState<Date>(new Date());
  const [reportType, setReportType] = useState<'NORMAL' | 'OFICIAL'>('NORMAL');

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const generateReport = async () => {
    const fromLeadYear = fromDate.getFullYear();
    const fromLeadMonth = fromDate.getMonth() + 1;
    const toLeadYear = toDate.getFullYear();
    const toLeadMonth = toDate.getMonth() + 1;
    /* const numberOfMonthsOfSale: 5 | 13 = instanceSlug === 'cl' ? 13 : 5; */

    try {
      await axios.post('/reports/generate', {
        type: reportType,
        fromLeadYear,
        fromLeadMonth,
        toLeadYear,
        toLeadMonth,
        numberOfMonthsOfSale: 5,
      });
      notify({
        status: 200,
        message: 'Reporte en cola, revise su lista de tareas',
      });
    } catch (err) {
      console.error('generateReport(): ', err);
    }
  };

  return (
    <>
      <Tooltip title="Generar reportes de ventas">
        <DownloadButton
          style={{ color: 'white' }}
          ref={ref}
          onClick={handleOpen}
        >
          {/* <Download style={styles.downloadIcon} /> */}
          <Download />
        </DownloadButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            padding: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ConditionalRender roles={['admin']}>
            <Autocomplete
              sx={{ mt: 2, mx: 1 }}
              disablePortal
              options={['NORMAL', 'OFICIAL']}
              defaultValue={reportType}
              onChange={(e, value: 'NORMAL' | 'OFICIAL') =>
                setReportType(value)
              }
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Tipo de reporte" />
              )}
            />
          </ConditionalRender>

          <DatePicker
            sx={{ mt: 2, mx: 1 }}
            inputVariant="outlined"
            views={['year', 'month']}
            label="Desde"
            value={fromDate}
            disableFuture
            onChange={(val) => {
              setFromDate(val);
            }}
          />
          <DatePicker
            sx={{ mb: 2, mt: 2, mx: 1 }}
            inputVariant="outlined"
            views={['year', 'month']}
            label="Hasta"
            value={toDate}
            disableFuture
            onChange={(val) => {
              setToDate(val);
            }}
          />
          <Button onClick={generateReport}>Generar</Button>
        </Box>
      </Popover>
    </>
  );
}

export default ExataHeaderDownloadReportBox;
